<template>
  <div class="container">
    <div class="container_mask">
      <div class="container_login">
        <div class="container_login_left">
          <div class="container_login_left_title">山东阳光志愿者服务平台</div>
          <div class="container_login_left_pic"><img src="../../assets/images/loginbg.gif" alt=""></div>
        </div>
        <div class="container_login_right">
          <!--                刷卡登录-->
          <div class="container_login_right_name" v-if="type == 1">
            <el-input
                ref="refInput"
                v-model="cardnum"
                class="w-50 m-2"
                size="large"
                placeholder="请刷卡登录"
                @keyup.enter="loginClick"
            />
          </div>
          <!--                手机号登录-->
          <div class="container_login_right_phonelogin" v-if="type == 2">
            <el-input
                ref="refInput"
                v-model="phonenum"
                class="w-50 m-2"
                size="large"
                placeholder="请输入手机号"
            >
            </el-input>
            <div class="container_login_right_phonelogin_code">
              <el-input
                  ref="refInput"
                  v-model="code"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入验证码"
                  @keyup.enter="loginClick"
              >
                <template class="template" #append>
                  <el-button @click="getCode">{{ loginCodeText }}</el-button>
                </template>
              </el-input>
            </div>

          </div>
          <!--                微信扫码登录-->
          <div class="container_login_right_weixinlogin" v-if="type == 3" id="qrcode">
            <img src="../../assets/images/qrcode.png">
            <div class="container_login_right_weixinlogin_text">
              请使用微信二维码扫码
            </div>
          </div>
          <div class="container_login_right_loginbtn" v-if="type == 1 || type == 2">
            <el-button @click="loginClick">登录</el-button>
          </div>
          <!--                刷卡登录-->
          <div class="container_login_right_type" v-if="type == 1">
            <div class="container_login_right_type_wechat" @click="changeType(3)">
              <div class="container_login_right_type_wechat_img">
                <img src="../../assets/images/login_weixin.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_wechat_text">
                微信登录
              </div>
            </div>

            <div class="container_login_right_type_password" @click="changeType(2)">
              <div class="container_login_right_type_password_img">
                <img src="../../assets/images/login_lock.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_password_text">
                验证码登录
              </div>
            </div>
          </div>


          <!--              手机号登录-->
          <div class="container_login_right_type" v-if="type == 2">
            <div class="container_login_right_type_wechat" @click="changeType(3)">
              <div class="container_login_right_type_wechat_img">
                <img src="../../assets/images/login_weixin.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_wechat_text">
                微信登录
              </div>
            </div>

            <div class="container_login_right_type_password" @click="changeType(1)">
              <div class="container_login_right_type_password_img">
                <img src="../../assets/images/login_card.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_password_text">
                刷卡登录
              </div>
            </div>
          </div>

          <!--              微信登录-->
          <div class="container_login_right_type" v-if="type == 3">
            <div class="container_login_right_type_wechat" @click="changeType(2)">
              <div class="container_login_right_type_wechat_img" >
                <img src="../../assets/images/login_lock.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_wechat_text">
                验证码登录
              </div>
            </div>

            <div class="container_login_right_type_password" @click="changeType(1)">
              <div class="container_login_right_type_password_img">
                <img src="../../assets/images/login_card.png"
                     alt=""/>
              </div>
              <div class="container_login_right_type_password_text">
                刷卡登录
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="Login">

import {nextTick, onMounted} from 'vue'
import {ref} from "vue-demi";
import {cardLogin, phonelogin, getSmsCode,getWeChatLogin} from "@/api/user"
import {useRouter} from "vue-router";
import {setToken, setUserId} from "../../utils/auth";
import {ElMessage} from "element-plus";
import url from '@/utils/URL'
const cardnum = ref('')
const phonenum = ref('')
const code = ref('')
const router = useRouter()
const refInput = ref()

const loginCodeText = ref('获取验证码');

//登录方式  1 刷卡登录 2 手机密码登录  3 微信登录
const type = ref(1);
const loginClick = async () => {
  //刷卡登录
  if (type.value == 1) {
    if (cardnum.value == null || cardnum.value == '') {
      ElMessage.error('请输入卡号!')
      return;
    }
    let result = await cardLogin({cardnum: cardnum.value.trim()});
    console.log(result)
    if (result.code === 200) {
      setToken(result.data.token)
      setUserId(result.data.user.id)
      router.push({name: 'Home'})
    }
  } else if (type.value == 2) {
    if (phonenum.value == null || phonenum.value == '') {
      ElMessage.error('请输入手机号!')
      return;
    }
    if (code.value == null || code.value == '') {
      ElMessage.error('请输入验证码!')
      return;
    }
    let result = await phonelogin({phone: phonenum.value.trim(), code: code.value.trim()});
    console.log(result)
    if (result.code === 200) {
      setToken(result.data.token)
      setUserId(result.data.user.id)
      router.push({name: 'Home'})
    }
  }

}
onMounted(() => {
  nextTick(() => {
    console.log("进入")
    refInput.value.focus()
  })
})

//切换登录方式 type要变成的登录方式
const changeType = (targetType) => {
  type.value = targetType;
  if(targetType === 3){
    getWeChatLoginMethod();
  }
}

const getCode = async () => {
  if (phonenum.value === '') {
    ElMessage.error('请输入手机号');
    return;
  }
  if (loginCodeText.value !== '获取验证码') {
    ElMessage.error('验证码已发送，请注意查收');
    return;
  }
  var timer;
  const params = {"phone": phonenum.value}
  let result = await getSmsCode(params);
  if (result.code === 200) {
    ElMessage.success(result.data.toString())
    console.log(result)
    var ss = 59
    timer = setInterval(function () {
      ss--
      loginCodeText.value = '验证码(' + ss + 's)'
      if (ss <= 0) {
        clearInterval(timer)
        loginCodeText.value = '获取验证码'
      }
    }, 1000)
  } else {
    clearInterval(timer);
    loginCodeText.value = '获取验证码';
  }

}

const  getWeChatLoginMethod = async () =>{
  let req = await getWeChatLogin
  if(req.code === 200){
    let resUrl = req.data.url;
    window.WxLogin({
      self_redirect:true,
      id:"qrcode",
      appid: "",
      scope: url.fontUrl,
      redirect_uri: resUrl,
      state: "",
      style: "white",
      href: ""
    });
  }
}


</script>

<style lang="scss" scoped>
::v-deep .el-input-group__append {
  width: 55px;
  height: 80px;
  line-height: 20px;
  border-radius: 0 10px 10px 0;
  background-color: rgba(255, 98, 0, 50);
  color: #FFFFFF;
}

.container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/mainbg.png') no-repeat;
  background-size: 100% 100%;

  &_mask {
    background-color: rgba($color: #000000, $alpha: 0.4);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_login {
    width: 50%;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;

    &_left {
      flex: 1;
      background: #fff;
      padding: 30px 50px 30px 30px;
      display: flex;
      flex-direction: column;

      &_title {
        width: 50%;
        color: rgba(255, 98, 0, 100);
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }

      &_pic {
        margin-top: 40px;
      }

      &_pic img {
        height: 320px;
      }
    }

    &_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #FF9959;

      &_name {

      }

      &_phonelogin {
        /*  padding-right: 30px;
          padding-left: 30px;*/
        width: 80%;

        &_code {
          margin-top: 20px;
          display: flex;

          :deep(.el-input__wrapper) {
            width: 340px;
            height: 80px;
            line-height: 20px;
            border-radius: 10px 0 0 10px;
            background-color: rgba(255, 98, 0, 50);
          }
        }
      }

      &_weixinlogin {
        &_text {
          margin-top: 15px;
          color: rgba(255, 255, 255, 100);
          font-size: 16px;
          text-align: center;
          font-weight: bold;
        }
      }

      &_weixinlogin img {
        width: 200px;
        height: 200px;
      }

      &_loginbtn {
        margin-top: 40px;
      }

      &_type {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 40px;

        &_wechat {
          margin-right: 100px;

          &_text {
            color: rgba(255, 255, 255, 100);
            font-size: 16px;
            text-align: left;
            margin-top: 15px;
            font-family: HarmonyOS_Sans_SC-bold;
            font-weight: bold;
          }

          &_img {
            border-radius: 30px;
            background-color: rgba(255, 255, 255, 100);
            width: 60px;
            height: 60px;
            display: table-cell;
            vertical-align: middle
          }
        }

        &_wechat img {
          width: 30px;
          height: 30px;
        }

        &_password {
          &_text {
            margin-top: 15px;
            color: rgba(255, 255, 255, 100);
            font-size: 16px;
            text-align: center;
            font-family: HarmonyOS_Sans_SC-bold;
            font-weight: bold;
          }

          &_img {
            border-radius: 30px;
            background-color: rgba(255, 255, 255, 100);
            width: 60px;
            height: 60px;
            display: table-cell;
            vertical-align: middle
          }
        }

        &_password img {
          width: 30px;
          height: 30px;
        }


      }
    }
  }
}

:deep(.el-input__inner) {

}

:deep(.el-input__wrapper) {
  width: 340px;
  height: 80px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(255, 98, 0, 50);
}

:deep(.el-button) {
  width: 370px;
  height: 80px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 100);
}
</style>
